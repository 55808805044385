import React from "react";

import useStyles from "_styles/atoms/buttons/textButton";

import BaseButton from "./baseButton";

const MainButton = props => {
	const {
		key,
		className,
		size = "md",
		onClick = () => {},
		title,
		rightSection,
		dataTestid,
		fullWidth,
		disabled,
		leftSection,
		id,
		loading,
		type,
		buttonTextClassName
	} = props;

	const { classes, cx } = useStyles ();

	return (
		<BaseButton
			key                 = { key }
			classNames           = { {
				root  : cx ( classes.primaryButton, className ),
				label : cx ( classes.buttonText, buttonTextClassName )
			} }
			dataTestid   = { dataTestid }
			disabled     = { disabled }
			fullWidth    = { fullWidth }
			id           = { id }
			leftSection  = { leftSection }
			loading      = { loading }
			onClick      = { onClick }
			rightSection = { rightSection }
			size         = { size }
			title        = { title }
			type         = { type }
			variant      = { "subtle" }
		/>
	);
};

export default MainButton;