import { createStyles } from "@mantine/emotion";

export default createStyles ( ( theme, props ) => {
	const btnState = props?.active ? "clicked" : "default";
	const button   = theme.other.colors.tertiaryBtn;

	const hoverColor           = props?.active ? button[btnState].text : button.hover.text;
	const hoverBackgroundColor = props?.active ? button[btnState].background : button.hover.background;

	return ( {
		tertiaryButton: {
			color      : button.default.text,
			background : props?.active ? button.clicked.background : button.default.background,

			"&:hover": {
				color      : hoverColor,
				background : hoverBackgroundColor
			}
		},
		buttonText: {
			color: button[btnState].text
		}
	} );
} );

