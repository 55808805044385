import { Button } from "@mantine/core";
import React      from "react";

const component = props => {
	const {
		key,
		className,
		size,
		variant = "outline",
		color = "primary",
		onClick = () => {},
		children,
		rightSection,
		dataTestid,
		fullWidth = false,
		leftSection,
		id
	} = props;

	return (
		<Button
			key       = { key }
			className = { className }
			color     = { color }
			data-testid = { dataTestid }
			fullWidth = { fullWidth }
			id = { id }
			leftIcon = { leftSection }
			onClick   = { onClick }
			radius = "xl"
			rightIcon = { rightSection }
			rightSection = { rightSection }
			size      = { size }
			styles = { {
				root: {
					 height: "100%"

				},
				label: {
					overflow: "unset"
				}
			} }
			variant   = { variant }
		>
			{ children }
		</Button>
	);
};

export default component;
