import { atom, useAtomValue, useSetAtom } from "jotai";

const pendingFunctionAtom = atom ();

export const useGetPendingFunction = () => useAtomValue ( pendingFunctionAtom );
export const useSetPendingFunction = () => useSetAtom ( pendingFunctionAtom );

export const webviewURLAtom = atom ( "" );

export const useGetWebviewURL = () => useAtomValue ( webviewURLAtom );
export const useSetWebviewURL = () => useSetAtom ( webviewURLAtom );