import { createStyles } from "@mantine/emotion";

export default createStyles ( ( theme, props ) => {
	const btnState =  props?.active ? "clicked" : "default";
	const button   = theme.other.colors.primaryBtn;

	return {
		primaryButton: {
			background: button[btnState].background,

			"&:hover": {
				// background: button.hover.background
			},
			"&[data-disabled]": {
				color   : "inherit",
				opacity : "0.5"
			}
		}
	};
} );
