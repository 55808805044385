import { Modal } from "@mantine/core";
import React     from "react";

import { useGetLoggedIn }   from "_common/hooks/auth";
import { useSetLoginPopup } from "_common/hooks/global";
import Button               from "_components/atoms/button";
import Typography           from "_components/atoms/typography";
import useStyles            from "_styles/molecules/confirmModal";

const ConfirmModal = ( {
	buttonComponent, performAction, dialogText,
	rtmpPage, logoutText, from, fromHomePage, dialogTitle = "",
	closeAfterActionPreformed, acceptButtonText, rejectButtonText
} ) => {
	const [ open, setOpen ] = React.useState ( false );
	const isLoggedIn        = useGetLoggedIn ();
	const { classes }       = useStyles ( undefined, { name: "styles" } );
	const setLoginPopup     = useSetLoginPopup ();

	const handleClickOpen = () => {
		if ( from === "report-modal" ) {
			if ( !isLoggedIn ) {
				return setLoginPopup ( true );
			} else {
				return setOpen ( true );
			}
		}

		return setOpen ( true );
	};

	const handleClose = () => {
		setOpen ( false );
	};

	const onAcceptHandler = () => {
		performAction ();
		if ( closeAfterActionPreformed ) {
			handleClose ();
		}
	};

	return (
		<div
			style = { { flex: rtmpPage ? "2 1 auto" : "none", width: from == "userInfoPopOver" ? "48%" : fromHomePage ? "100%" : "auto" } }
		>
			<div
				onClick = { handleClickOpen }
				style = { { width: from == "userInfoPopOver" ? "100%" : "" } }
			>
				{buttonComponent}
			</div>

			<Modal
				centered
				aria-describedby = "alert-dialog-description"
				aria-labelledby = "alert-dialog-title"
				classNames = { { content: classes.dialogPaper, root: classes.dialogContainer, body: classes.modalBody } }
				onClose = { handleClose }
				opened = { open }
				withCloseButton = { false }
				zIndex = { 999999 }
			>
				<div
					className = { classes.dialogContent }
				>
					{/* { logoutText && (
                        <div className = { classes.logoutText }>
                            {logoutText}
                            ?
                        </div>
                    )} */}

					{dialogTitle ? (
						<Typography
							className = { classes.title }
							// color = "primary"
							title = { dialogTitle }
						/>
					) : null}

					<Typography
						// color = "primary"
						color = "secondary"
						title = { dialogText }
						variant = "body1"
					/>
				</div>

				<div
					className = { classes.dialogActions }
				>

					<Button
						autoFocus
						className = { classes.acceptButton }
						color = "primary"
						onClick = { onAcceptHandler }
					>
						<Typography
							className = { classes.acceptButtonText }
							title = { acceptButtonText ? acceptButtonText : logoutText ? logoutText : "Yes" }
							variant = "body1"
						/>
					</Button>

					<Button
						className = { classes.rejectButton }
						color = "primary"
						onClick = { handleClose }
					>
						<Typography
							className = { classes.rejectButtonText }
							title = { rejectButtonText ? rejectButtonText : logoutText ? "Cancel" : "No" }
						/>

					</Button>
				</div>
			</Modal>
		</div>
	);
};

export default ConfirmModal;
