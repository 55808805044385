import React from "react";

import useStyles from "_styles/atoms/buttons/secondaryButton";

import BaseButton from "./baseButton";

const SecondaryButton = ( {
	key,
	className,
	onClick = () => {},
	leftSection,
	rightSection,
	active,
	title,
	buttonTextClassName,
	...rest
} ) => {

	const { classes, cx } = useStyles ( { active } );

	return (
		<BaseButton
			{ ...rest }
			key                 = { key }
			classNames           = { {
				root  : cx ( classes.secondaryButton, className ),
				label : cx ( classes.buttonText, buttonTextClassName )
			} }
			leftSection  = { leftSection }
			onClick      = { onClick }
			rightSection = { rightSection }
			title        = { title }
			variant      = { "default" }
		/>
	);
};

export default SecondaryButton;
